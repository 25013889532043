import { post } from "@/api/http";
import { jsFn } from "@/utils/jsFn";
import profile from "./profile";
var serverLocation = "global";

var clientID = profile.clientID[serverLocation];
var apiUrl = profile.apiUrl[serverLocation];
var clientsecret = profile.clientsecret[serverLocation];
// global : 正式 / globalrc : rc站 / globaltest : 測試站
var ies5ApiUrl = profile.ies5ApiUrl["globalrc"];// 預設RC站
if(location.host === "localhost:5005" || location.host === "community-test.teammodel.net"){// 本地或是測試站
  ies5ApiUrl = profile.ies5ApiUrl["globaltest"];//profile.ies5ApiUrl["globaltest"];
}else if(location.host === "community.teammodel.net"){// 正式站
  ies5ApiUrl = profile.ies5ApiUrl["globalrc"];
}else if(location.host === "htcommunity-rc.teammodel.net"){// RC站
  ies5ApiUrl = profile.ies5ApiUrl["globalrc"];
}

export default {
  // getCosmosExample: function (data) {
  //   return post("/example/get-cosmos", data);
  // },
  loginTmdID: function () {
    let entryurl = location.href;
    let nonceStr = jsFn.getUUID();
    let accountUrltest = profile.accountUrl['globalrc'];
    window.location.href = accountUrltest + "/oauth2/authorize?" + "response_type=code&client_id=" +clientID + "&state=louise_test&nonce=" + nonceStr + "&redirect_uri=" + encodeURIComponent(decodeURIComponent(entryurl));
  },
  logout: function (tmid) {
    let data = {
      client_id: clientID,
      id: tmid,
    };
    return new Promise((resolve, reject) => {
      post(apiUrl + "/oauth2/logout", data).then(
        (res) => {
          if (res.error) {
            reject(new Error(res));
          } else {
            resolve(res);
          }
        },
        (err) => {
          reject(err);
        }
      );
    });
  },

  // 取得快速登入CODE
  getCode: function (idToken, isTicket) {
    let nonceStr = jsFn.getUUID();
    let type = isTicket ? "ticket" : "code";
    let data = {
      grant_type: type,
      client_id: clientID,
      nonce: nonceStr,
      id_token: idToken,
    };
    return new Promise((resolve, reject) => {
      post(apiUrl + "/oauth2/login", data).then(
        (res) => {
          if (res.error) {
            reject(new Error(res));
          } else {
            resolve(res[type]);
          }
        },
        (err) => {
          reject(err);
        }
      );
    });
  },
  getToken: function () {
    let url = new URL(window.location.href);
    let data = {
      grant_type: "authorization_code",
      client_id: clientID,
      code: url.searchParams.has("code") == true ? url.searchParams.get("code") : "",
    };
    return post(apiUrl + "/oauth2/token", data);
  },
  getTokenUnlogin: function () {
    let data = {
      client_id: clientID,
      client_secret: clientsecret,
      grant_type: "device",
    };
    return post(apiUrl + "/oauth2/token", data);
  },

  getSas: function () {
    return post("/Material/sas");
  },
  createMaterial: function (data) {
    return post("/Material/createMaterial", data);
  },
  updateMaterials: function (data) {
    return post("/Material/updateMaterials", data);  
  },
  getAllMaterials: function (data) {
    return post("/Material/getAllMaterials",data);
  },
  getMaterialsByUserId: function (data) {
    return post("/Material/getMaterialsByUserId", data);
  },
  getMaterialsByGroupId: function (data) {
    return post("/Material/getMaterialsByGroupId", data);
  },
  getMaterialById: function (data) {
    return post("/Material/getMaterialById", data);
  },
  getMaterialsByType: function (data) {
    return post("/Material/getMaterialsByType", data);
  },
  getMaterialsBySearch: function (data) {
    return post("/Material/getMaterialsBySearch", data);
  },
  saveAllMaterialsHits: function (data) {
    return post("/Material/saveAllMaterialsHits", data);
  },
  getRelatedMaterials: function (data) {
    return post("/Material/getRelatedMaterials", data);
  },
  deleteMaterialById:function(data){
    return post("/Material/deleteMaterialById", data);
  },
  updateMaterialById:function(data){
    return post("/Material/updateMaterialById", data);
  },
  createUserInfo: function (data) {
    return post("/User/createUserInfo", data);
  },
  getUserInfoById: function (data) {
    return post("/User/getUserInfoById", data);
  },
  updateUserInfoById: function (data) {
    return post("/User/updateUserInfoById", data);
  },
  createReportMsg: function (data) {
    return post("/ReportMsg/createReportMsg", data);
  },
  getAllReportMsgs:function(data){
    return post("/ReportMsg/getAllReportMsgs",data)
  },
  getAllReportMsgsMaterialId:function(data){
    return post("/ReportMsg/getAllReportMsgsMaterialId",data)
  },
  updateReportMsgById:function(data){
    return post("/ReportMsg/updateReportMsgById",data)
  },
  createMessage:function(data){
    return post("/Message/createMessage",data)
  },
  getMessageByUserId:function(data){
    return post("/Message/getMessageByUserId",data)
  },
  createMessagesByToIds:function(data){
    return post("/Message/createMessagesByToIds",data)
  },
  createGroup:function(data){
    return post("/Group/createGroup",data)
  },
  getGroupsIattend:function(data){
    return post("/Group/getGroupsIattend",data)
  },
  getGroupsIcreated:function(data){
    return post("/Group/getGroupsIcreated",data)
  },
  getGroupInfoById:function(data){
    return post("/Group/getGroupInfoById",data)
  },
  updateGroupInfoById:function(data){
    return post("/Group/updateGroupInfoById",data)
  },
  deleteGroupMembers:function(data){
    return post("/Group/deleteGroupMembers",data)
  },
  getAllUsers:function(data){
    return post("/User/getAllUsers",data)
  },
  getCoreUsers:function(data){
    return post("/User/get-coreuser",data)
  },
  createResourcePack(data){
    return post("/ResourcePack/createResourcePack",data)
  },
  getResourcePacksIcreated(data){
    return post("/ResourcePack/GetResourcePacksIcreated",data)
  },
  getResourcePackInfoById(data){
    return post("/ResourcePack/GetResourcePackInfoById",data)
  },
  updateResourcePackInfoById(data){
    return post("/ResourcePack/UpdateResourcePackInfoById",data)
  },
  deleteResourcePackById(data){
    return post("/ResourcePack/DeleteResourcePackById",data)
  },
  getResourcePackByGroupId(data){
    return post("/ResourcePack/GetResourcePackByGroupId",data)
  },
  updatePacks(data){
    return post("/ResourcePack/UpdatePacks",data)
  },
  testcourselist(data){    
    return post("/User/read-test-upon",data)
  },
  getPurchaseData(data){    
    return post("/Purchase/get-purchase-data",data)
  },
  // 取得IES5老師個人課程
  getIesTeacherCourse(data){    
    return post("/user/get-ies-teacher-course",data)
  },  
  // 取得統測活動
  getJointEventFind(data){    
    return post(ies5ApiUrl + "/joint/event/find",data)
  },
  // 取得統測教師課程及組別
  getJointCourseFind(data){    
    return post(ies5ApiUrl + "/joint/course/find",data)
  },
  // 新建修改教師課程及組別
  setJointCourseUpsert(data){    
    return post(ies5ApiUrl + "/joint/course/upsert",data)
  },
  // 取得統測評量
  getJointExamFind(data){    
    return post(ies5ApiUrl + "/joint/exam/find",data)
  },
  // 活動寄送Email
  ActivitySentEmail(data){    
    return post( "/mail/send",data)
  },
  // 新增參賽班級名單
  setJointCourseUpsertPlus(data){    
    return post(ies5ApiUrl + "/course-base/create-simple",data)
  },
  // 取得IES5 domain
  geties5ApiUrl(){ 
    if(location.host === "community.teammodel.net" || location.host === "event.teammodel.net"){// 正式站
      ies5ApiUrl = profile.ies5ApiUrl["global"];
    }else if(location.host === "htcommunity-rc.teammodel.net"){// RC站
      ies5ApiUrl = profile.ies5ApiUrl["globalrc"];
    }
    return ies5ApiUrl;
  }  
};
